import React, { useEffect, useState } from 'react';
import { useDispatch, } from 'react-redux';
import { assigned_userActions } from '../../actions';
import { UserAvatar } from '../../components'
import CheckIcon from '../../images/icons/check.svg';

const AssignUserCheckboxGroup = ({ name, options,  input, meta: { touched, error }, ...rest}) => {
  const dispatch = useDispatch();
  return(
    <>
      {options && options.length > 0 ?
          options.map((option, index) => {
            return(
              <div className="dropdown-item checkbox" key={index}>
                <label className="d-flex align-items-center">
                  <UserAvatar
                    icon={ option.logo.url && option.logo.icon.url ? option.logo.icon.url : null }
                    name={ option.first_name + " " + option.last_name }
                    name_initials={ option.name_initials }
                    size="24"
                    cached={false}
                  />

                  <span className="assigned-user-checkbox__name ml-3">
                    {option.first_name} {option.last_name}
                    {!option.first_name && !option.last_name ? option.email : ""}
                  </span>

                  <input type="checkbox"
                    name={`${input.name}_user_id`}
                    value={option.id}
                    checked={input.value.indexOf(option.id) !== -1}
                    onChange={(event) => {
                      const newValue = [...input.value];
                      if (event.target.checked) {
                        newValue.push(option.id);
                      } else {
                        newValue.splice(newValue.indexOf(option.id), 1);
                        dispatch(assigned_userActions.uncheckdValue({ user_id: option.id, _destroy: true}));
                      }
                      return input.onChange(newValue);
                    }}/>

                    { input.value.indexOf(option.id) !== -1 &&
                      <span className="dropdown__selected--with-check">
                        <img src={CheckIcon} alt="Selected" />
                      </span>
                    }
                </label>
              </div>
            )
          })
        :
        null
      }
    </>
  )
}

export default AssignUserCheckboxGroup;
