import { alertActions } from './';
import { userConstants, notificationConstants } from '../constants';
import { history } from '../helpers';
import { makeGETRequest, makePOSTRequest, makeDELETERequest, makePUTRequest } from '../utils/Axios';

export const notificationActions = {
  getPage,
  read,
  unRead,
  readAll
};

export function getPage(only_unread, lastNotificationId, success_callback, filter_type) {
  return dispatch => {
    try {
      dispatch({ type: userConstants.NOTIFICATIONS_LOADING });
      makeGETRequest(`/api/v1/notifications?last_id=${lastNotificationId ? lastNotificationId : ''}&unread=${only_unread ? 1 : 0}${filter_type ? '&filter_type='+filter_type : ''}`)
      .then(response => {
        if (response.data.status === "ok"){
          dispatch({ type: notificationConstants.GET_ALL_SUCCESS, payload: response })
          dispatch({type: notificationConstants.UNREAD_NOTIFICAITON_COUNT, payload: response.data.unread_notifications_count})
        } else {
          dispatch({ type: notificationConstants.GET_ALL_FAILURE, payload: response })
        }

        success_callback(response.data)
      })
    } catch {
    }
  }
}

export function read(notificationId) {
  return dispatch => {
    try{
      makePUTRequest(`/api/v1/notifications/${notificationId}/read`)
      .then(response => {
        if (response.data.status === "ok"){
          dispatch({ type: notificationConstants.READ_SUCCESS, payload: response })
          dispatch({type: notificationConstants.UNREAD_NOTIFICAITON_COUNT, payload: response.data.notification_count})
        } else {
          dispatch({ type: notificationConstants.READ_FAILURE, payload: response })
        }
      })
    } catch {
    }
  }
}

export function unRead(notificationId) {
  return dispatch => {
    try{
      makePUTRequest(`/api/v1/notifications/${notificationId}/unread`)
      .then(response => {
        if (response.data.status === "ok"){
          dispatch({ type: notificationConstants.UNREAD_SUCCESS, payload: response })
          dispatch({type: notificationConstants.UNREAD_NOTIFICAITON_COUNT, payload: response.data.notification_count})
        } else {
          dispatch({ type: notificationConstants.READ_FAILURE, payload: response })
        }
      })
    } catch {
    }
  }
}

export function readAll(successCallback) {
  return dispatch => {
    try{
      // dispatch({ type: userConstants.PAGE_LOADING });
      makePUTRequest(`/api/v1/notifications/read_all`)
      .then(response => {
        if (response.data.status === "ok"){
          dispatch({ type: notificationConstants.UNREAD_NOTIFICAITON_COUNT, payload: 0 })
          dispatch({ type: notificationConstants.READ_ALL_SUCCESS, payload: response })

          successCallback()
        } else {
          dispatch({ type: notificationConstants.READ_ALL_FAILURE, payload: response })
        }
      })
    } catch {
    }
  }
}
