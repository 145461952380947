import { Cursor } from '../utils/cursor'

class FieldUserTagSupport {
  static createUserTag = (user) => {
    let html = `<tag class="user_tag" tag-type="User" tag-id=${user.user_id} contentEditable="false">@${user.user_full_name }</tag>`

    let node = document.createElement('tag')
    node.setAttribute('class', 'user_tag')
    node.setAttribute('tag-type', 'User')
    node.setAttribute('tag-id', user.user_id)
    node.setAttribute('contentEditable', 'false')
    node.innerHTML = `@${user.user_full_name}`

    return node
  }

  static user_selected = (input, user, searchString) => {
    input.focus();

    let positionRangeAfter = (range, node) => {
      range.setEndAfter(node)
      range.setStartAfter(node)
    }

    if (typeof window.getSelection === 'undefined') {
      return;
    }

    let sel = window.getSelection()

    if (typeof sel.getRangeAt === 'undefined') {
      return;
    }

    var range, offset;

    if (sel.rangeCount >= 1) {
      range = sel.getRangeAt(0)

      let originalOffset = Cursor.getCurrentCursorPosition(input)

      let node = FieldUserTagSupport.createUserTag(user)
      range.insertNode(node)
      positionRangeAfter(range, node)

      let textNode = document.createTextNode(' ')
      range.insertNode(textNode)
      positionRangeAfter(range, textNode)

      if (input.innerHTML.match(searchString)) {
        input.innerHTML = input.innerHTML.replace(searchString, '')

        sel = window.getSelection()
        var range = document.createRange();
        range.selectNodeContents(input);

        range.collapse(true)
        //range.setStart(node, 0)
        //range.setEnd(node, 0)
        
        sel.removeAllRanges()
        sel.addRange(range) 

        offset = originalOffset - searchString.length + user.user_full_name.length + 2
        Cursor.setCurrentCursorPosition(offset, input)
        input.focus()
      }
    }
  }
}

export { FieldUserTagSupport }