import { useState, useContext, useRef, useEffect } from 'react';
import { UserSummaryContext } from '../contexts/UserSummaryContext'
import { Image } from 'react-bootstrap';

function UserAvatar(props) {
  const {
    name,
    name_initials,
    icon,
    size,
    className,
    user_id,
    workspace_id,
    organization_id,
    bordered,
    containerClassName,
    cached = true,
    openUserSummary
  } = props

  const {
          userSummaryDetails,
          setUserSummaryDetails,
          setIsActive,
          isActive
        } = useContext(UserSummaryContext);

  const [image_cached, setImageCached] = useState(false);
  const [image_url, setImageUrl] = useState(false);

  useEffect(() => {
    if (image_cached && cached === true) {
      return null;
    }

    if (icon) {
      setImageUrl(icon)
      setImageCached(true);
    }
  }, [icon]);


  const handleShow = (e) => {
    if(!isActive && openUserSummary){
      e.stopPropagation();

      const boundary = avatarRef.current?.getBoundingClientRect();
      setIsActive(true);
      setUserSummaryDetails({id: null, boundary: boundary, user_id: user_id, workspace_id: workspace_id, organization_id: organization_id})
    }
  }

  const avatarRef = useRef(null);

  const style = () => {
    return {
      float: 'left',
      height: '' + size + 'px',
      minWidth: '' + size + 'px',
      width: '' + size + 'px',
      fontSize: '' + (parseInt(size) * 0.5) + 'px',
      border: bordered ? '3px solid' : 'none',
      textAlign: 'center'
    }
  }

  const imageStyle = () => {
    return {
      float: 'left',
      height: '' + size + 'px',
      width: '' + size + 'px',
      border: bordered ? '3px solid white' : 'none'
    }
  }

  return (
    <>
      <div
        onClick={handleShow}
        ref={avatarRef}
        className={`${containerClassName}`}
      >
        {
          image_url ?
            <Image
              src={ image_url }
              title={ name }
              alt={ name }
              roundedCircle
              className={ className }
              style={imageStyle()}
            />
          :
            <div
              className={`user-avatar ${className}`}
              style={style()}
            >
              <div>{ name_initials }</div>
            </div>
        }
      </div>
    </>
  );
}

export { UserAvatar };
